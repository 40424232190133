.App {
  text-align: center;
  margin: 0 auto !important;
  background-color: black;
  min-height: 100vh;
}

h1,
h3,
p,
form {
  color: #eee;
}

.app-body {
  max-width: 1600px;
  margin: 0 auto !important;
}

.header {
  background-color: black;
  margin-bottom: 40pt;
}

.logo {
  max-height: 60pt;
  padding: 10pt;
}

table {
  margin-bottom: 50pt;
  width: 100%;
}

td {
  padding-left: 10pt;
  padding-right: 10pt;
  font-size: 10pt;
  border: 1pt solid #282c34;
}

.filters {
  width: 100%;
  margin: 20pt auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eee;
}

.loc-filter {
  width: 30%;
  display: inline-block;
  margin-right: 20pt;
}

.time-filter {
  width: 30%;
  display: inline-block;
}

/*
.loc-filter > div > div {
  background-color: #333 !important;
  color: #999 !important;
}

.time-filter > div > div {
  background-color: #333 !important;
  color: #999 !important;
}
*/

.scene-container {
  width: 100%;
  margin-bottom: 50pt;
}

.scene-header-row {
  display: flex;
  margin-bottom: 5pt;
  width: 100%;
  font-weight: 600;
  color: #eee;
}

.scene-row {
  display: flex;
  background-color: #999;
  border-radius: 5pt;
  padding: 3pt 0;
  margin-bottom: 5pt;
  width: 100%;
}

.scene-cell-text {
  font-size: 10pt;
  width: 36%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-page {
  font-size: 10pt;
  width: 3%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-number {
  font-size: 10pt;
  width: 3%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-int {
  font-size: 10pt;
  width: 8%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-location {
  font-size: 10pt;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-time {
  font-size: 10pt;
  width: 10%;
  margin-left: 1%;
  margin-right: 1%;
}

.scene-cell-expand {
  font-size: 12pt;
  width: 3%;
  margin-left: 1%;
  margin-right: 1%;
  cursor: pointer;
}

.btn-see-all {
  margin-left: 10pt;
  font-size: 16pt;
  cursor: pointer;
  color: #eee;
}
