.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 80vw;
  background-color: #222;
  display: flex;
  flex-direction: column;
}

.modal-footer {
  height: 20pt;
  padding: 10pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5pt;
}

.modal-body {
  padding: 10pt 10pt 0 10pt;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
  display: flex;
  flex-direction: row;
  max-height: 80vh;
}

.modal-lines {
  width: 70%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  max-height: 80vh;
  background-color: #333;
}

.modal-properties {
  width: 30%;
  padding-left: 5pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-line {
  font-family: "Courier New", Courier, monospace;
  font-size: 11pt;
  font-weight: 400;
  line-height: 18pt;
  color: #eee;
  margin: 0;
}

.modal-header {
  color: #eee;
  font-family: "Courier New", Courier, monospace;
  font-size: 11pt;
  font-weight: 600;
  line-height: 18pt;
  margin: 20pt 0 0 0;
}

.modal-property {
  background-color: #777;
  width: 100%;
  margin-bottom: 5pt;
  border-radius: 5pt;
}

.prop-header {
  color: white;
  font-size: 12pt;
  font-weight: 800;
  margin: 10pt 0;
}

.prop-value {
  color: white;
  font-size: 11pt;
  margin: 10pt 5pt;
}

.prop-stub {
  font-size: 11pt;
  color: #ddd;
  margin: 25pt 0;
}
